<template>
  <div class="budget__card">
    <div :class="percentage ? '' : 'not-available-card'">
      <img src="../../assets/img_health_budget.svg" alt="" />
      <h3 class="mb-16">स्वास्थ्य क्षेत्र</h3>
    </div>
    <div class="budget-bar mb-16" v-if="percentage">
      <span class="value" :style="{ width: percentage + '%' }"></span>
    </div>
    <!-- <div class="not-available-text" v-else>
      <img src="../../assets/img_no-budget-available.svg" alt="" />
      <p>यस क्षेत्रको अहिले बजेट उपलब्ध छैन!</p>
    </div> -->
    <p class="amount" v-if="percentage != ''">{{ data }}</p>
  </div>
</template>
<script>
export default {
  name: "healthSector",
  props: {
    data: { type: String, required: true },
    percentage: { type: String, required: true },
  },
};
</script>
