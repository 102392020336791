<template>
  <div class="budget-wrapper">
    <div class="mb-32 component__header">
      <h1>कार्यक्षेत्रगत बजेट</h1>
    </div>
    <div class="container budget__content">
      <div class="budget__filter mb-24" v-if="years.length">
        <Multiselect
          v-model="selected_year"
          :options="years"
          :placeholder="default_year"
          :canClear="false"
        />
      </div>
      <div class="budget__content--inner mb-80">
        <h2 class="mb-16">
          वर्गबाट विभाजीत विकास कार्यक्रम तथा कार्यक्षेत्रगत बजेट
        </h2>
        <div class="budget__card mb-24">
          <div
            class="budget__card--title mb-40"
            v-if="metadata.total_project_budget"
          >
            <span class="total-amount"
              >रु.
              {{ englishToNepaliNumber(metadata.total_project_budget / 1000) }}
              <small>(रुपैयाँ हजारमा)</small></span
            >
          </div>
          <BarChart :data="chartData" :noData="chartData.length == 0" />
        </div>
      </div>
      <div class="budget__content--inner">
        <h2 class="mb-16">विषयगत क्षेत्रहरुको बजेट</h2>
        <div class="flex budget__card-sector">
          <EducationSector
            :data="getSectorData('शिक्षा')"
            :percentage="getSectorPercentage(sectorData['शिक्षा'])"
          ></EducationSector>
          <HealthSector
            :data="getSectorData('स्वास्थ्य')"
            :percentage="getSectorPercentage(sectorData['स्वास्थ्य'])"
          ></HealthSector>
          <AgricultureSector
            :data="getSectorData('कृषि')"
            :percentage="getSectorPercentage(sectorData['कृषि'])"
          ></AgricultureSector>
          <WaterSector
            :data="getSectorData('खानेपानी तथा सरसफाइ')"
            :percentage="getSectorPercentage(sectorData['खानेपानी तथा सरसफाइ'])"
          ></WaterSector>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import Multiselect from "@vueform/multiselect";
import Budget from "./../../repositories/Budget";
import EducationSector from "./../../components/budget/educationSector.vue";
import HealthSector from "./../../components/budget/healthSector.vue";
import AgricultureSector from "./../../components/budget/agricultureSector.vue";
import WaterSector from "./../../components/budget/waterSector.vue";
import { englishToNepaliNumber } from "nepali-number";
import { getEnglishNumber } from "./../../helper/helper";
// import NoDataFound from "../../components/NoDataFound.vue";

const BarChart = defineAsyncComponent(() =>
  import("../../components/BarChart.vue")
);

export default {
  components: {
    Multiselect,
    BarChart,
    EducationSector,
    HealthSector,
    AgricultureSector,
    WaterSector,
    // NoDataFound,
  },
  data() {
    return {
      englishToNepaliNumber,
      metadata: [],
      chartData: [],
      sectorData: [],
      budget: [],
      selected_year: "",
      default_year: "",
      years: {},
      default: {
        placeholder: "multiselect-placeholder",
      },
    };
  },
  watch: {
    selected_year: {
      handler: "getFilteredBudget",
    },
  },
  async mounted() {
    try {
      let { data } = await Budget.getBudget();
      let budget = data.data;
      this.years = this.getYearsOption(data.metadata.years);
      this.metadata = budget.length ? data.data[0].sdg_chart.metadata : [];
      this.chartData = budget.length ? data.data[0].chart_json : [];
      this.sectorData = budget.length ? data.data[0].sector_json : [];
      this.default_year = "आ. व. " + data.metadata.years[this.years.length - 1];
      this.$store.dispatch("setLoading", false);
    } catch (err) {
      console.error(err);
    }
  },

  methods: {
    getSectorData(sector) {
      return Object.hasOwnProperty.call(this.sectorData, sector)
        ? "रु. " + englishToNepaliNumber(this.sectorData[sector])
        : "-";
    },
    getSectorPercentage(sector_budget) {
      let budget_per =
        typeof sector_budget == "number"
          ? String((sector_budget / this.metadata.total_project_budget) * 100)
          : "";

      return budget_per;
    },
    getYearsOption(years) {
      let tempYear = [];

      for (const year in years) {
        tempYear.push({ value: years[year], label: "आ. व. " + years[year] });
      }

      return tempYear;
    },
    async getFilteredBudget() {
      this.selected_year = this.selected_year
        ? this.selected_year
        : this.years[this.years.length - 1].value;
      let year = this.selected_year ? getEnglishNumber(this.selected_year) : "";
      let { data } = await Budget.getBudget(`?year=${year}`);
      let budget = data.data;
      this.metadata = budget.length ? data.data[0].sdg_chart.metadata : [];
      this.chartData = budget.length ? data.data[0].chart_json : [];
      this.sectorData = budget.length ? data.data[0].sector_json : [];
    },
  },
  computed: {
    checkChartdata() {
      return Object.keys(this.chartData).length ? false : true;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.budget {
  &-wrapper {
    padding-bottom: 80px;
    .multiselect {
      height: 44px;
      width: 180px;
    }
  }
  &__filter {
    float: right;
    /* margin-top: -120px; */
  }
  &__content {
    max-width: 1290px;
    padding: 0 20px;
    position: relative;
    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 40px;
      &:after {
        display: none;
      }
    }
    ul li:only-child {
      margin-bottom: 0;
    }
    &--inner {
      clear: both;
      @media all and (max-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }
  &__card {
    background: $neutrals-white;
    border-radius: 20px;
    /* min-height: 300px; */
    padding: 24px;
    h2 {
      font-size: 32px;
      &:after {
        display: none;
      }
    }
    h3 {
      margin-top: 10px;
    }
    &--title {
      float: right;
    }

    @media all and (max-width: 900px) {
      margin-bottom: 32px;
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
    @media all and (max-width: 600px) {
      text-align: center;
      &:nth-child(3) {
        margin-bottom: 32px;
      }
    }
  }
}
.total-amount {
  color: $primary;
  background: -webkit-linear-gradient(#67c3f3, #5a98f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 800;
  line-height: 60px;
  small {
    color: $neutrals-black;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    -webkit-text-fill-color: $neutrals-black;
    vertical-align: middle;
  }
}

.amount {
  font-size: 24px;
  font-weight: 700;
}

.budget-bar {
  background: $primary-light;
  border-radius: 4px;
  height: 30px;
  @media all and (max-width: 600px) {
    margin: 0 auto 16px;
  }

  .value {
    background: linear-gradient(135deg, #f97794 0%, #623aa2 100%);
    border-radius: 4px;
    display: block;
    height: 30px;
  }
}
.budget__card-sector {
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: 24%;
  }
  svg {
    margin-bottom: 12px;
  }
  @media all and (max-width: 900px) {
    > div {
      width: 48%;
    }
  }
  @media all and (max-width: 600px) {
    display: block;
    > div {
      width: 100%;
    }
  }
}
</style>
